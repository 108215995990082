





















































import { Component, Prop, Vue } from 'vue-property-decorator'
import { GameModule } from '@/store/modules'

@Component
export default class CharacterCard extends Vue {
  @GameModule.Mutation setViewCharacter

  @Prop() character
  @Prop({ type: Boolean, default: false }) adminSearch
  @Prop() gameAccountName

  showOverlay = false

  getCharacterPath() {
    const gmPath = `/gm/characters/view/${this.character.id}`
    const userPath = `/user/${this.$route.params.id}/view/${this.character.id}`
    const characterPath = `/character/${this.character.id}`

    if (this.adminSearch) {
      this.setViewCharacter(this.character)
      window.open(this.$router.resolve({ path: gmPath }).href, '_blank')
    } else {
      this.$router.push(this.$route.params.id ? userPath : characterPath)
    }
  }
}
